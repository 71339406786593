import React from 'react';
import './Footer.css';
import SocialLinks from "../SocialLinks/SocialLinks";
import Wrapper from "../Wrapper/Wrapper";
import FloatingWhatsApp from "../FloatingWhatsApp/FloatingWhatsApp";
import ScrollToTop from "react-scroll-to-top";

function Footer(props) {
    return (
        <Wrapper>
            <footer>
                <SocialLinks orientation={'horizontal'}/>
                <hr/>
                <p>Lautaro Aguirre &copy; 2023. ALL RIGHTS RESERVED.</p>
            </footer>


            <FloatingWhatsApp/>
            <ScrollToTop smooth color={"dodgeblue"} />
        </Wrapper>

    );
}

export default Footer;