import React from "react";
import Section from "../Section/Section";

export default function MyBeginnings(props) {
    return (
        <Section title="My beginnings">

            <p>
                I began playing with computers when I was around 4 years old, enjoying great classic games of that time, and I continued to learn how to fix computers that came my way (I ended up breaking 7 PCs). I was always interested in the technical side of things.
            </p>

            <p>
                My interest in Computer Security and Web Design began when I was 8 years old, after hearing my mother talk about some "hackers." Without delay, I started searching and learning about "web design" using platforms and software like wix.com, webs.com, foroactivo.com, jimdo.com, SMF, Spirate, website x5, Dreamweaver, and Frontpage. During that time, I used to play some MMORPG and FPS games, and clan forums were a trend, so I learned how to "create" forums, search for scripts, download them, search for web hosting, upload them via FTP, create and configure databases, set permissions, and install them, all at just 10 years old.
            </p>

            <p>
                During the heyday of Taringa!, it was fashionable to create clones of that Link Sharing System (now a Social Network). This is where I learned basic HTML, CSS, and PHP skills. I became proficient at modifying themes and uploading them to production.
            </p>

            <p>
                When I was 14 years old, I watched the movie "The Social Network," which inspired me to seriously start learning HTML5, CSS, JavaScript, and, most importantly, PHP with MySQL, with the aspiration of creating a truly ambitious project (which is now obsolete and poorly developed): an improved 2004 Facebook clone.
            </p>

            <p>
                Another ambitious project was Brax, a complex SaaS platform for Game Hosting that kept me entertained for several years.
            </p>
        </Section>
    );
}