import React from "react";
import SkillItem from "./../SkillItem/SkillItem";
import './SkillsList.css';

export const SkillsList = ({techStack, includes, showName, logoSize, onClick}) => {
    return (<ul className={'skills-list'}>
        {techStack.map((item, index) => {
            return (includes.includes(item.name) || includes === 'all') && <SkillItem name={item.name} logo={item.logo} showName={showName} logoSize={logoSize} index={index} onClick={onClick}/>}
        )}
    </ul>);
}
