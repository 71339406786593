import React from 'react';

import StrongTitleEmdash from '../StrongTitleEmdash/StrongTitleEmdash';

import './Skills.css';
import { SkillsList } from "../SkillsList/SkillsList";

export default function Skills({techStack, includes}) {
    return (
        <section className="skills">
            <StrongTitleEmdash>Technologies</StrongTitleEmdash>
            <p>I only add the technologies that I have actually used in real, serious projects.</p>
            <div>
                <SkillsList techStack={techStack} includes={includes}/>
            </div>
        </section>

    );
}