import React from 'react';

import Section from '../Section/Section';
import './Projects.css';

export const Projects = ({children}) => {
    return <Section title="Projects">
        <h3>Personal & Freelance projects</h3>


        <div className={'projects'}>
            {children}
        </div>
    </Section>;
}