import React from "react";

import './HireMe.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function HireMe() {
	return <div className={'hire-content'}>
			<h1><FontAwesomeIcon icon="fa-brands fa-telegram-plane" size="2xl" style={{color: "#282c34"}} /> LET'S TALK AND WE WILL SOLVE IT.</h1>
			<p>I am available for Freelance projects. Hire me and get your project well done!</p>
			<p>Send me a WhatsApp!</p>
		</div>;
}