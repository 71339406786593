import React from "react";
import {SkillsList} from "../components/SkillsList/SkillsList";
import {useParams} from "react-router-dom";
import Carousel from "../components/Carousel/Carousel";
import './Project.css';
import Footer from "../components/Footer/Footer";
import HireMe from "../components/HireMe/HireMe";
import Section from "../components/Section/Section";
import Wrapper from "../components/Wrapper/Wrapper";
import PersonalBanner from "../components/PersonalBanner/PersonalBanner";
import Header from "../components/Header/Header";
import ScrollToTop from "react-scroll-to-top";

export default function Project({projects}) {
	let { projectSlug } = useParams();

	const project = projects.find(project => project.slug === projectSlug);

	if (!project) {
		// Handle the case where no matching project is found
		console.log('Project not found');
		return <div>Project not found</div>;
	}

	const {
		title,
		lightText,
		techStack,
		usedTechStack,
		description,
		client,
		functions,
		carousel
	} = project;

	return (<React.Fragment>
		<Header/>
		<PersonalBanner/>
		<Wrapper>
			<Section title={title}>
				<div className={'project'}>
					<span className="light-text">{lightText}</span>
					<p>{description}</p>

					<h3>CLIENT</h3>
					<p>{client}</p>
					<h3>TECH STACK</h3>
					{techStack && usedTechStack && <SkillsList techStack={techStack} includes={usedTechStack} showName={false} logoSize="small"/>}
					{
						functions && <>
							<h4><strong>Some functionalities</strong></h4>
							<ul>
								{functions.map(item => <li>{item}</li>)}
							</ul>
						</>
					}

					<h3>IMAGES</h3>
					<Carousel carousel={carousel} />
				</div>
			</Section>
		</Wrapper>
		<HireMe/>
		<Footer/>
		<ScrollToTop smooth color={"dodgeblue"} />
	</React.Fragment>);
}