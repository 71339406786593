import React from 'react';

import './SocialLinks.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function SocialLinks({orientation='vertical'}) {
    return (
        <nav id="social-links" className={"social-links-"+orientation}>
            <ul>
                <li>
                    <a href="https://linkedin.com/in/lautagui" target="_blank">
                        <FontAwesomeIcon icon="fab fa-linkedin" size="lg" />
                    </a>
                </li>
                <li>
                    <a href="https://github.com/thisbar" target="_blank">
                        <FontAwesomeIcon icon="fab fa-github" size="lg" />
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/@lautaro_dev" target="_blank">
                        <FontAwesomeIcon icon="fab fa-youtube" size="lg" />
                    </a>

                </li>
                <li>
                    <a href="https://linktr.ee/lautagui" target="_blank">
                        <FontAwesomeIcon icon="fas fa-link" size="lg" />
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default SocialLinks;
