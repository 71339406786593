import React from "react";
import StrongTitleEmdash from "../StrongTitleEmdash/StrongTitleEmdash";
import "./Section.css";

export default function Section({title, children}) {

    return (
        <section>
            <StrongTitleEmdash>{title}</StrongTitleEmdash>
            {children}
        </section>
    );
}