import React from "react";
import "./SkillItem.css";

export default function SkillItem({name, showName, logo, logoSize, index, onClick}) {
    return  (
        <li className={[logoSize + ' grid']} key={name} onClick={() => onClick && onClick(index)}>
            <img src={require('../../assets/imgs/stacks/'+logo)} alt={name}/>
            {showName && name}
        </li>
    );
}

SkillItem.defaultProps = {
    showName: true,
    logoSize: 'large'
};