import React from "react";
import {Carousel as ResponsiveCarousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.css';

export default function Carousel({carousel}) {

    return <ResponsiveCarousel
        showArrows={true}
        autoPlay={true}
        renderThumbs={() => false}
        swipeable={true}
        emulateTouch={true}
    >
    {carousel && carousel.map(item => {
        const src = require(`../../assets/imgs/projects/`+item.src);

        return <div className='carousel-item'>
            {

                item.type === 'img'
                    && <img
                    src={src}
                    alt={item.alt}
                    />
            }

            {
                item.type === 'video'
                    && <video autoPlay controls tabIndex="0">
                        <source src={src} type='video/mp4' />
                    </video>
            }

            {item.legend && <p className="legend">{item.legend}</p>}
        </div>
    })}
    </ResponsiveCarousel>;
}