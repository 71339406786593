import React from 'react';

import RoundedProfilePhoto from '../RoundedProfilePhoto/RoundedProfilePhoto';
import SocialLinks from '../SocialLinks/SocialLinks';
import Particles from 'particlesjs/dist/particles.min';
import TextCarousel from '../TextCarousel/TextCarousel';

import './Cover.css';
import Header from "../Header/Header";
import Logo from "../Logo/Logo";

class Cover extends React.Component {
    componentDidMount() {
        Particles.init({
            selector: '.particles-background',
            color: ['#1E90FF', '#ffffff'],
            speed: .2,
            connectParticles: true,
            maxParticles: 150,
            minDistance: 80
        });
    }

    render() {
        return (
            <React.Fragment>
                <canvas className="particles-background"/>
                <section id="cover">

                    <RoundedProfilePhoto alt="Foto de Perfil de Lautaro Aguirre"/>
                    <div>
                        <ul>
                            <li><h1>Hello!</h1></li>
                            <li><h1>I'm <strong>Lautaro Aguirre</strong>, a Software Developer</h1></li>
                            <li><h1>I
                                <TextCarousel texts={[
                                    'love to innovate',
                                    'am an autodidact',
                                    'am always learning',
                                    'am a problem solver',
                                    'a content creator',
                                    'take care of details',
                                    'love to create things',
                                    'write elegant code for humans',
                                ]}/>
                            </h1></li>
                            <li><Logo/></li>
                        </ul>
                        <SocialLinks/>
                    </div>

                </section>
            </React.Fragment>

        );
    }
}

export default Cover;