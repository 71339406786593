import React from "react";
import {FloatingWhatsApp as FloatingWhatsAppButton} from 'react-floating-whatsapp';
import avatar from '../RoundedProfilePhoto/profilephoto.png';
export default function FloatingWhatsApp() {
	return <FloatingWhatsAppButton
		accountName={"Lautaro Aguirre"}
		phoneNumber={"+54 9 11 2163-9935"}
		avatar={avatar}
		chatMessage={"Hello! How can I help you?"}
		allowEsc
		allowClickAway
	/>
}