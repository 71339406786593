import React from 'react';
class TextCarousel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTextIndex: 0
        };

        this.defaultProps = {
            texts: ''
        };

        this.tick = this.tick.bind(this);
    }

    componentDidMount() {
        this.interval = setInterval(this.tick, 3000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    tick() {
        this.state.currentTextIndex + 1 < this.props.texts.length ? this.setState(prevState => (
            { currentTextIndex: prevState.currentTextIndex + 1 }
        )) : this.setState({currentTextIndex: 0});
    }

    render() {
        return  <strong className="carousel-animation"> {this.props.texts[this.state.currentTextIndex]}</strong>
    }
}

export default TextCarousel;