import React from 'react';
import './Header.css';
import {Link, NavLink} from "react-router-dom";
import Logo from "../Logo/Logo";

function Header() {
    return (
            <header>
                <div>
                    <Link to={'/'}><Logo/></Link>

                    <nav>
                        <ul>
                            <li><NavLink to={'/'}>Home</NavLink></li>
                        </ul>
                    </nav>
                </div>
            </header>
    );
}

export default Header;