import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Project from "./pages/Project";

import {library} from "@fortawesome/fontawesome-svg-core";
import {fab, faGithub, faLinkedin, faYoutube} from '@fortawesome/free-brands-svg-icons';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import {faLink} from "@fortawesome/free-solid-svg-icons";

library.add(fab, faTelegramPlane, faYoutube, faLink, faGithub, faLinkedin);
function App() {
    const techStack = [
        {
            name: 'PHP 8',
            environment: 'backend',
            logo: 'php.png',
            description: `I developed from small projects with monolitich architecture to large projects with microservices and hexagonal architecture
            working with the Operating System layer and performing advanced stream operations. Also I have a solid
            experience developing REST APIs and their SDKs.`,
        },
        {
            name: 'Symfony',
            environment: 'backend',
            logo: 'symfony.png',
            description: `I use Symfony Framework to create my application endpoints and middlewares (it takes care of the HTTP requests).`,
        },
        {
            name: 'Slim',
            environment: 'backend',
            logo: 'slim.png',
            description: `I use Slim Framework to create my application endpoints and middlewares (it takes care of the HTTP requests).`,
        },
        {
            name: 'Doctrine',
            environment: 'backend',
            logo: 'doctrine.png',
            description: `Its an ORM/ODM. I use it to save time avoiding develop repositories and improving the security (it's open source, well tested and makes the job).`
        },
        {
            name: 'MySQL',
            environment: 'database',
            logo: 'mysql.png',
            description: 'The relational database that we all know.'
        },

        {
            name: 'MongoDB',
            environment: 'database',
            logo: 'mongodb.png',
            description: 'I use MongoDB on high availability projects that may need to scale quickly or their schemas may be different between documents.'
        },
        {
            name: 'Redis',
            environment: 'cache',
            logo: 'redis.png',
            description: 'I use Redis for fast in-memory cache.'
        },
        {
            name: 'Elasticsearch',
            environment: 'database',
            logo: 'elasticsearch.png',
            description: `I learn Elasticsearch when my team at Incluyeme.com was facing a issue: how to search across more than 
            +400.000 records quickly and with advanced queries?
            
            I developed an internal tool to export records from the MySQL database to Elasticsearch indexes.`
        },
        {
            name: 'Docker',
            environment: 'infrastructure',
            logo: 'docker.png',
            description: `Its an ORM/ODM. I use it to save time avoiding develop repositories and improving the security (it's open source, well tested and makes the job).`
        },
        {
            name: 'PHPUnit',
            environment: 'testing',
            logo: 'phpunit.svg',
            description: `I use Slim Framework to create my application endpoints and middlewares (it takes care of the HTTP requests).`,
        },
        {
            name: 'Behat',
            environment: 'testing',
            logo: 'behat.png',
            description: `I use Slim Framework to create my application endpoints and middlewares (it takes care of the HTTP requests).`,
        },
        {
            name: 'New Relic',
            environment: 'backend',
            logo: 'new-relic.png',
            description: `I use Slim Framework to create my application endpoints and middlewares (it takes care of the HTTP requests).`,
        },
        {
            name: 'JavaScript',
            environment: 'frontend',
            logo: 'js.png',
            description: `I learn JS ES5/ES6 in the year 2016. I have used it to create interactive web platforms and consuming APIs.
        Currently I am learning Node.js due some advantages for which PHP was not designed. The muscles of a web page.`
        },
        {
            name: 'React',
            environment: 'frontend',
            logo: 'react.png',
            description: 'I use React to develop performant cross-platform applications quickly and with reusable components to save time.',
        },
        {
            name: 'AppSearch',
            environment: 'search',
            logo: 'elasticsearch.png',
            description: `I would loved find AppSearch when I used to work at Incluyeme.com
      It is Elasticsearch optimized to develop search functionality quickly without take care of complex search algorithms.`
        },
        {
            name: 'React Router',
            environment: 'frontend',
            logo: 'react-router.png',
            description: `It is a React library that helps developers with the website Navigation. Useful for Single Page Applications.`
        },
        {
            name: 'React Navigation',
            environment: 'mobile',
            logo: 'reactnavigation.png',
            description: `Like React Router but for React Native.`
        },
        {
            name: 'React Native',
            environment: 'mobile',
            logo: 'react.png',
            description: 'It uses React on his core to develop applications for mobile devices.',
        },
        /*    {
			  name: 'GraphQL',
			  environment: 'backend',
			  logo: 'graphql.png',
			  description: `I use GraphQL to prevent over-fetching and under-fetching.`
		  },*/
        {
            name: 'AWS',
            environment: 'cloud',
            logo: 'aws.png',
            description: `It is a Cloud service provided by Amazon. I have used EC2, Beanstalk, RDS, Route53, Rekognition, Translate, ELB, Textract
            and Comprehend for small and large projects.`
        },
        {
            name: 'GIT',
            environment: 'version control',
            logo: 'git.png',
            description: `I use GIT to keep my code stable and safe between changes.`
        },
        {
            name: 'Swagger',
            environment: 'documentation',
            logo: 'swagger.png',
            description: `I use GIT to keep my code stable and safe between changes.`
        },
        {
            name: 'Firebase',
            environment: 'backend',
            logo: 'firebase.png',
            description: `I use Firebase to host my React apps and to send Cloud Messages (notifications).`
        },

        {
            name: 'HTML5',
            environment: 'frontend',
            logo: 'html5.png',
            description: 'The bones of a web page.'
        },
        {
            name: 'CSS3',
            environment: 'frontend',
            logo: 'css3.png',
            description: 'The skin of a web page.'
        },
        {
            name: 'jQuery',
            environment: 'frontend',
            logo: 'jquery.png',
            description: 'An old library for creating user interfaces.'
        },
        {
            name: 'WordPress',
            environment: 'cms',
            logo: 'wordpress.png',
            description: 'A CMS written in PHP. It was made to let non technical people able to create their own sites. I have experience creating plugins.'
        },
    ];
    const projects = [
        {
            title: "Responder",
            slug: "responder",
            techStack: techStack,
            usedTechStack: [
                'PHP 8',
                'Symfony',
                'Doctrine',
                'MongoDB',
                'Docker',
                'PHPUnit',
                'Behat',
                'React',
                'React Native',
                'JavaScript',
                'AWS',
                'Redis',
                'HTML5',
                'CSS3'
            ],
            description: "It is a life-critical SaaS web and mobile platform for first responders developed to alert firefighters when there is an emergency and the fire station has not on duty personnel. Was designed to have speed on his communications, high availability and failure proof. An inventory module was developed so each user can have items with some data such as: items quantity, size, variants, mark, model, acquisition date, acquisition state and current state. It also records every user action with the session and device data.",
            client: "Responder.com.ar",
            carousel: [
                { type: 'img', src: 'roperia/agregar-grupo-adquisicion.PNG' },
                { type: 'img', src: 'responder/alerta.png' },
                { type: 'img', src: 'roperia/agregar-categoria.PNG' },
                { type: 'img', src: 'roperia/grupos-adquisiciones.PNG' },
                { type: 'img', src: 'roperia/historial.png' },
                { type: 'img', src: 'roperia/personal-perfil.png' }
            ]
        },
        {
            title: "CAAM (Work in Progress)",
            slug: "caam",
            techStack: techStack,
            usedTechStack: ['PHP 8', 'Doctrine', 'MongoDB', 'JavaScript', 'React', 'HTML5', 'CSS3'],
            description: "It is a web app developed for a martial arts school to react to the COVID-19 pandemic. The teachers can create classes with a title, descriptions, sex (male, female, both), age range, days, schedule, monthly price (with a payment gateway), and max. student slots. The students can buy the available classes for them and pay with various payment methods. The admins can organize events such combats with matchmaking system, statistics about the fighters, inscriptions and public information about the combat.",
            client: "CAAM",
            carousel: [
                { type: 'img', src: 'caam/caam.png' },
                { type: 'img', src: 'caam/combate.png' }
            ]
        },
        {
            title: "Fastnive",
            slug: "fastnive",
            techStack: techStack,
            usedTechStack: ['PHP 8', 'MySQL', 'JavaScript', 'HTML5', 'CSS3'],
            description: "Was a random games app. The players have to deposit items (according to the room) of a game (like CS:GO) that will be drawn between the players when the countdown becomes zero. The winner can withdraw the items to his Steam inventory.",
            client: "Fastnive.net",
            carousel: [{ type: 'img', src: 'fastnive/sala.png' }]
        },
        {
            title: "Server Info",
            slug: "server-info",
            techStack: techStack,
            usedTechStack: ['PHP 8', 'MySQL', 'JavaScript', 'HTML5', 'CSS3'],
            description: "I developed a web app to query game-servers (with sockets) and display server information such as: IP, title, game server version, game modes, language, slots, status (on/off) and players (avatars, nickname, time and score).",
            client: "Revo-Gamers.com",
            carousel: [{ type: 'img', src: 'rgpcu/serverinfo.png' }]
        },
        {
            title: "Master List",
            slug: "master-list",
            techStack: techStack,
            usedTechStack: ['PHP 8', 'MySQL', 'JavaScript', 'HTML5', 'CSS3'],
            description: "I developed a web app to query game-servers (with sockets) and display server information such as: IP, title, game server version, game modes, language, slots, status (on/off) and players (avatars, nickname, time and score).",
            client: "Revo-Gamers.com",
            carousel: [
                { type: 'img', src: 'masterlist/index.png' },
                { type: 'img', src: 'masterlist/server.png' },
                { type: 'img', src: 'masterlist/estadisticas.png' },
                { type: 'img', src: 'masterlist/perfil.png' }
            ]
        },
        {
            title: "Codex",
            slug: "codex",
            techStack: techStack,
            usedTechStack: ['JavaScript', 'HTML5', 'CSS3'],
            description: "I developed the front-end for a cloud-based IDE with files and folders on the left panel, file metadata on the bottom, and the styled source code.",
            client: "Codex",
            carousel: [{ type: 'img', src: 'codex/codex.png' }]
        },
        {
            title: "Technical Connection",
            slug: "technical-connection",
            techStack: techStack,
            usedTechStack: ['PHP 8', 'MySQL', 'JavaScript', 'HTML5', 'CSS3'],
            description: "This was my first project. It was based on the 2004’s thefacebook.com site (current Facebook.com) with design and functional improvements. I developed it when I was 14 years old, please do not expect it to look professional.",
            client: "Technical-Connection.net",
            functions: [
                'Site settings',
                'Posts with filters in images, HTML tags, favorites, report and comments',
                'Friends',
                'Friends activities',
                'Search',
                'Chat (with optional random chat)',
                'Class schedules',
                'Date app (like Facemash/Tinder)',
                'Top users',
                'Find people by school, career or class',
                'Profile with custom color, music, cover, gallery, user, profile data.',
                'Invite people'
            ],
            carousel: [
                { type: 'img', src: 'technicalconnection/nuevo_logo.png' },
                { type: 'img', src: 'technicalconnection/Inicio_compartir.png' },
                { type: 'img', src: 'technicalconnection/horarios.png' },
                { type: 'img', src: 'technicalconnection/escuelas.png' },
                { type: 'img', src: 'technicalconnection/nuevodiseñocasi.png' },
                { type: 'img', src: 'technicalconnection/nuevodiseñoprivacidad.png' },
                { type: 'img', src: 'technicalconnection/p3.png' },
                { type: 'img', src: 'technicalconnection/paneladmin.png' },
                { type: 'img', src: 'technicalconnection/perfil.png' },
                { type: 'img', src: 'technicalconnection/pinfo.png' },
                { type: 'img', src: 'technicalconnection/reposicionamiento_divs.png' },
                { type: 'img', src: 'technicalconnection/tc.png' },
                { type: 'img', src: 'technicalconnection/verfotos.png' }
            ]
        },
        {
            title: "Premium RP",
            slug: "premium-rp",
            techStack: techStack,
            usedTechStack: ['JavaScript', 'HTML5', 'CSS3'],
            description: "I developed the registration step form for a User Control Panel for a SA-MP Game-Server.",
            client: "Premium Roleplay",
            carousel: [{ type: 'img', src: 'prp/registro.png' }]
        },
        {
            title: "PawnCMDDocumentation",
            slug: "pawn-cmd-documentation",
            techStack: techStack,
            usedTechStack: ['PHP 8', 'AWS'],
            description: "It is a PHP script that searches user-defined parameters and then translates it to several languages (with AWS Translate) and saves the results in different outputs (such as JSON) for each translation in AWS S3 (uploads in batches) or local file system. It also generates documentation about the search, such as: on what files the pattern was found or detected language. This app was designed and is useful for translating source code (like texts or commands in SA-MP Pawn game modes).",
            client: "Revo-Gamers.com",
            carousel: [{ type: 'img', src: 'no-image.jpg' }]
        },
        {
            title: "Candidate Finder",
            slug: "candidate-finder",
            techStack: techStack,
            usedTechStack: ['PHP 8', 'MySQL', 'JavaScript', 'Elasticsearch', 'HTML5', 'CSS3'],
            client: "HR",
            description: "It was a web app developed to search job candidates (with their resume data) with filters such as: names, address or location, tags,  keywords (searched in resume), email address, phone, job/career title and companies. A plugin written for WordPress syncs every CRUD action related to a candidate with millions of Elasticsearch documents to keep real-time search. The app shows the candidate profile and resume data with files uploaded by him, and also translates this data with AWS Translate if necessary. The HR can give a score, favourite star or “tag” to a candidate.",
            carousel: [{ type: 'img', src: 'no-image.jpg' }]
        },
    ];

    return (

      <BrowserRouter>
          <Routes>
              <Route path="/project/:projectSlug" element={<Project projects={projects}/>} />
              <Route path="/" element={<Home techStack={techStack} projects={projects}/>} />
          </Routes>
      </BrowserRouter>



  );
}

export default App;
