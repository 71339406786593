import React from 'react';
import "./ProjectThumb.css";
import {Link} from "react-router-dom";

export default function ProjectThumb({title, slug, lightText, techStack, usedTechStack, description, functions, carousel}) {
    const thumb = require(`../../assets/imgs/projects/`+carousel[0].src);

    return (
        <Link to={'/project/'+slug}>
            <div className={'project-thumb'}>
                <img src={thumb} alt={title}/>
                <h3><strong>{title}</strong> <span className="light-text">{lightText}</span></h3>
            </div>
        </Link>

    )
}
