import React from "react";

import Section from "../Section/Section";
import CareerCard from "./../CareerCard/CareerCard";
import './Career.css';

export default function Career({techStack}) {

    return (
        <Section title="Career">
            <p>
                I have a full set of skills that allows me to develop end-to-end applications, from requirements, architecture planning and design to programming, testing and deploy.
                My strength is the back-end but I'm also solid on the front-end.
            </p>

            <ul className={"career-grid"}>
                <CareerCard
                    title="Avalith"
                    lightText="jul 2022 - now"
                    usedTechStack={['PHP 8', 'Slim', 'Docker', 'PHPUnit', 'Behat', 'Doctrine', 'Redis', 'Elasticsearch', 'Swagger', 'New Relic', 'JavaScript', 'React']}
                    techStack={techStack}
                    description="Working at a Massive streaming platform<br>
                        <ul>
                            <li>Document APIs using Swagger (OAS 3.0).</li>
                            <li>Implement observability with New Relic APM.</li>
                            <li>Migrate from MVC to Hexagonal Architecture with dockerized microservices in Lumen using events.</li>
                            <li>Testing (PHPUnit / Behat): Unit, Integration, Acceptance.</li>
                            <li>Enhance the platform by developing new features that provide high executive visibility.</li>
                            <li>Collaborate with managers in decision-making and guide team members in matters of architecture, good practices, code review and clean code.</li>
                            <li>Dive team members into hexagonal architecture, repository patterns and ORM's like Doctrine and testing.</li>
                            <li>Lead trainees and juniors to improve their required skills for both personal growth and the success of the team.</li>
                        </ul>

                        <p>I gave several talks to tens of people about ergonomy, health at remote work and setups.</p>


                        <h4>Grows (in-company courses):</h4>
                        <ul>
                            <li>ReactJS</li>
                            <li>NodeJS</li>
                            <li>Swagger (OAS 3.0)</li>
                            <li>SCRUM/JIRA</li>
                        </ul>"
                />

                <CareerCard
                    title="Symbar - Grupo Hasar"
                    lightText="nov 2021 - ago 2022"
                    usedTechStack={['PHP 8', 'Slim', 'MySQL', 'Doctrine', 'PHPUnit', 'jQuery', 'JavaScript']}
                    techStack={techStack}
                    description="I played a crucial role at Suticket.com, developing vital code for complex algorithms related to seat options and payments with a strong focus on backend design following DDD and Hexagonal Architecture. I also designed IoT monitoring dashboards, integrated MQTT-based Apache Mosquitto for real-time data, and implemented SMS and email alerts for sensor anomalies. Moreover, I optimized e-commerce logistics by solving the Travelling Salesman problem, utilizing Docker, VROOM, and mapping APIs for efficient routing."
                />

                <CareerCard
                    title="Incluyeme.com"
                    lightText="feb 2019 - oct 2019"
                    usedTechStack={['PHP 8', 'MySQL', 'Elasticsearch', 'AWS', 'WordPress', 'JavaScript']}
                    techStack={techStack}
                    description="I developed stand-alone internal tools and WordPress plugins to enhance recruiter performance. These tools enabled recruiters to search through millions of records in milliseconds using advanced filters and segmentation, all within a custom user interface with Elasticsearch at the core of the application. I implemented services such as Elasticsearch, Amazon Web Services Comprehend, and Textract.

This tool was required to synchronize millions of pre-existing records with Elasticsearch on low-spec startup servers. Additionally, I improved the accessibility of all Latin American websites, ensuring that individuals with disabilities could utilize the platform."
                />
            </ul>

        </Section>
    );
}