import React from "react";
import Cover from "../components/Cover/Cover";
import Skills from "../components/Skills/Skills";
import Career from "../components/Career/Career";
import {Projects} from "../components/Projects/Projects";
import ProjectThumb from "../components/ProjectThumb/ProjectThumb";
import MyBeginnings from "../components/MyBeginnings/MyBeginnings";
import Footer from "../components/Footer/Footer";
import HireMe from "../components/HireMe/HireMe";
import Wrapper from "../components/Wrapper/Wrapper";
import PersonalBanner from "../components/PersonalBanner/PersonalBanner";
import ScrollToTop from "react-scroll-to-top";
import FloatingWhatsApp from "../components/FloatingWhatsApp/FloatingWhatsApp";

export default function Home({techStack, projects}) {
	return <React.Fragment>
		<Wrapper>
			<Cover/>
			<Skills techStack={techStack} includes="all"/>
			<Career techStack={techStack}/>
		</Wrapper>
		<PersonalBanner/>
		<Wrapper>
			<Projects>
				{projects.map(project => <ProjectThumb {...project}/>)}
			</Projects>
		</Wrapper>
		<HireMe/>
		<Wrapper>
			<MyBeginnings/>
		</Wrapper>

		<Footer/>
	</React.Fragment>
}