import React from 'react';

import './RoundedProfilePhoto.css';

function RoundedProfilePhoto(props) {
    return <img className="rounded-profile-photo" src={require('./'+props.photo)} alt={props.alt}/>;
}

RoundedProfilePhoto.defaultProps = {
    photo: 'profilephoto.png',
    alt: 'Foto de Perfil'
};

export default RoundedProfilePhoto;